<template>
  <v-app>
    <v-app-bar app dark color="primary" tile>
      <v-app-bar-title>
        {{ $t("app.title") }}
      </v-app-bar-title>
      <v-spacer></v-spacer>
      <v-btn text @click="changePage('PrivacyPolicy')">{{
        $t("app.routes.privacyPolicy")
      }}</v-btn>
      <v-btn text @click="changePage('TermsOfUse')">{{
        $t("app.routes.termsOfUse")
      }}</v-btn>
      <v-btn text @click="changePage('FAQ')">{{ $t("app.routes.faq") }}</v-btn>
    </v-app-bar>
    <v-main class="grey lighten-5">
      <v-container fluid class="px-5">
        <br />
        <br />
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
export default {
  methods: {
    changePage(pageName) {
      this.$router.push({ name: pageName }).catch(() => {});
    },
  },
  created() {
    this.$i18n.locale = this.$route.params.locale
  }
};
</script>