import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import i18n from './i18n';
import momentTimezone from 'moment-timezone'

Vue.config.productionTip = process.env.NODE_ENV
Vue.use(require('vue-moment'), { momentTimezone });

new Vue({
    vuetify,
    //store,
    router,
    i18n,
    render: h => h(App)
}).$mount('#app')
