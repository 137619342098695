<template>
  <v-card elevation="0" tile>
    <div v-if="this.$i18n.locale == 'pt' || this.$i18n.locale == 'ar'">
      <v-card-text class="pb-0" v-html="$t('app.policyhtml')"></v-card-text>
    </div>
    <div v-else>
      <h1>
        {{ $t("app.policy.title.label") }}
      </h1>

      <h2>
        <strong>{{ $t("app.policy.subtitle.label") }}</strong>
      </h2>
      <br />
      <p>
        {{ $t("app.policy.subtitle.value.item1") }}
      </p>
      <p>
        {{ $t("app.policy.subtitle.value.item2") }}
      </p>
      <p>
        <strong> {{ $t("app.policy.policies.policy1.label") }}</strong>
      </p>
      <p>
        {{ $t("app.policy.policies.policy1.subtitle") }}
      </p>
      <p>{{ $t("app.policy.policies.policy1.value.item1") }}</p>
      <p>{{ $t("app.policy.policies.policy1.value.item2") }}</p>
      <p>
        {{ $t("app.policy.policies.policy1.value.item3.label") }}
      </p>
      <ul type="disc">
        <li>{{ $t("app.policy.policies.policy1.value.item3.value.item1") }}</li>
        <li>
          {{ $t("app.policy.policies.policy1.value.item3.value.item2") }}
        </li>
      </ul>
      <p>
        {{ $t("app.policy.policies.policy1.value.item4.label") }}
      </p>
      <ul type="disc">
        <li>
          {{ $t("app.policy.policies.policy1.value.item4.value.item1") }}
        </li>
        <li>{{ $t("app.policy.policies.policy1.value.item4.value.item2") }}</li>
        <li>
          {{ $t("app.policy.policies.policy1.value.item4.value.item3") }}
        </li>
        <li>
          {{ $t("app.policy.policies.policy1.value.item4.value.item4") }}
        </li>
      </ul>
      <p>
        <strong>
          {{ $t("app.policy.policies.policy2.label") }}
        </strong>
      </p>
      <ul type="disc">
        <li>
          {{ $t("app.policy.policies.policy2.value.item1") }}
        </li>
        <li>
          {{ $t("app.policy.policies.policy2.value.item2") }}
        </li>
        <li>
          {{ $t("app.policy.policies.policy2.value.item3") }}
        </li>
        <li>
          {{ $t("app.policy.policies.policy2.value.item4") }}
        </li>
        <li>
          {{ $t("app.policy.policies.policy2.value.item5") }}
        </li>
        <li>
          {{ $t("app.policy.policies.policy2.value.item6") }}
        </li>
        <li>
          {{ $t("app.policy.policies.policy2.value.item7") }}
        </li>
        <li>{{ $t("app.policy.policies.policy2.value.item8") }}</li>
        <li>
          {{ $t("app.policy.policies.policy2.value.item9") }}
        </li>
      </ul>
      <p>
        {{ $t("app.policy.policies.policy2.text1") }}
      </p>
      <p>
        {{ $t("app.policy.policies.policy2.text2") }}
      </p>
      <p>
        <strong> {{ $t("app.policy.policies.policy3.label") }}</strong>
      </p>
      <p>
        {{ $t("app.policy.policies.policy3.value.item1") }}
      </p>
      <p>
        {{ $t("app.policy.policies.policy3.value.item2") }}
      </p>
      <p>
        {{ $t("app.policy.policies.policy3.value.item3") }}
      </p>
      <p>
        <strong>{{ $t("app.policy.policies.policy4.label") }}</strong>
      </p>
      <p>
        {{ $t("app.policy.policies.policy4.value.item1") }}
      </p>
      <p>
        {{ $t("app.policy.policies.policy4.value.item2") }}
      </p>
      <p>
        {{ $t("app.policy.policies.policy4.value.item3") }}
      </p>
      <p>
        <strong>{{ $t("app.policy.policies.policy5.label") }}</strong>
      </p>
      <p>
        {{ $t("app.policy.policies.policy5.value.item1") }}
        <a href="mailto:wbdr@wfh.org%20"> {{ $t("app.policy.link") }}</a
        >.
      </p>
      <p>
        {{ $t("app.policy.policies.policy5.value.item2") }}
      </p>
      <p>
        <strong>
          {{ $t("app.policy.policies.policy6.label") }}
        </strong>
      </p>
      <p>
        {{ $t("app.policy.policies.policy6.value.item1") }}
      </p>
      <p>
        <strong> {{ $t("app.policy.policies.policy7.label") }}</strong>
      </p>
      <p>
        {{ $t("app.policy.policies.policy7.value.item1") }}
      </p>
      <p>
        {{ $t("app.policy.policies.policy7.value.item2") }}
      </p>
      <p>
        <strong>{{ $t("app.policy.contactUs.label") }}</strong>
      </p>
      <p>
        {{ $t("app.policy.contactUs.value") }}
        <a href="mailto:info@probestudy.org">{{ $t("app.policy.link") }}</a>
      </p>
    </div>
  </v-card>
</template>
<script>
export default {};
</script>