import Vue from 'vue';
import VuetifyLib from 'vuetify/lib';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

if (process.env.NODE_ENV == "developpement") {
    VuetifyLib.config.silent = true
}

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: "#e31837",
                accent: "#9d9fa2"

            }
        }
    }
});
