<template>
  <v-card tile elevation="0">
    <div v-if="this.$i18n.locale == 'pt' || this.$i18n.locale == 'ar'">
      <v-card-text class="pb-0" v-html="$t('app.faqhtml')"></v-card-text>
    </div>
    <div v-else>
      <h1>
        {{ $t("app.faq.title.label") }}
        <strong>{{ $t("app.faq.title.value") }}</strong>
      </h1>
      <br />
      <p>
        <strong>{{ $t("app.faq.questions.question1.title") }}</strong>
      </p>
      <ol>
        <p>
          {{ $t("app.faq.questions.question1.value") }}
        </p>
      </ol>
      <p>
        <strong>{{ $t("app.faq.questions.question2.title") }}</strong>
      </p>
      <ol>
        <p>{{ $t("app.faq.questions.question2.value") }} <br /></p>
      </ol>
      <p>
        <strong>{{ $t("app.faq.questions.question3.title") }}</strong>
      </p>
      <ol>
        <p>
          {{ $t("app.faq.questions.question3.value") }}
        </p>
      </ol>
      <p>
        <strong> {{ $t("app.faq.questions.question4.title") }} </strong>
      </p>
      <ul>
        <li>{{ $t("app.faq.questions.question4.value.item1") }}</li>

        <li>{{ $t("app.faq.questions.question4.value.item2") }}</li>

        <li>{{ $t("app.faq.questions.question4.value.item3") }} <br /></li>
      </ul>
      <p>
        <strong>{{ $t("app.faq.questions.question5.title") }} </strong>
      </p>
      <ol>
        <p>
          {{ $t("app.faq.questions.question5.value.item1") }}
        </p>

        <p>
          {{ $t("app.faq.questions.question5.value.item2") }}
        </p>

        <p>
          {{ $t("app.faq.questions.question5.value.item3") }}
        </p>

        <p>
          {{ $t("app.faq.questions.question5.value.item4") }}
        </p>

        <p>{{ $t("app.faq.questions.question5.value.item5") }} <br /></p>
      </ol>
      <p>
        <strong>{{ $t("app.faq.questions.question6.title") }}</strong>
      </p>
      <ol>
        <p>{{ $t("app.faq.questions.question6.value") }} <br /></p>
      </ol>
      <p>
        <strong>{{ $t("app.faq.questions.question7.title") }} </strong>
      </p>
      <ol>
        <p>{{ $t("app.faq.questions.question7.value") }}<br /></p>
      </ol>
      <p>
        <strong>{{ $t("app.faq.questions.question8.title") }} </strong>
      </p>
      <ul>
        <li>
          {{ $t("app.faq.questions.question8.value.item1") }}
        </li>

        <li>{{ $t("app.faq.questions.question8.value.item2") }}</li>

        <li>{{ $t("app.faq.questions.question8.value.item3") }}<br /></li>
      </ul>
      <p>
        <strong>{{ $t("app.faq.questions.question9.title") }} </strong>
      </p>
      <ol>
        <p>{{ $t("app.faq.questions.question9.value") }}<br /></p>
      </ol>
      <p>
        <strong>{{ $t("app.faq.questions.question10.title") }}</strong>
      </p>
      <ol>
        <p>{{ $t("app.faq.questions.question10.value") }} <br /></p>
      </ol>
      <p>
        <strong>{{ $t("app.faq.questions.question10.title") }}</strong>
      </p>
      <ol>
        <p>
          {{ $t("app.faq.questions.question10.value") }} <br />
          <br />
        </p>
      </ol>

      <p>
        <strong>{{ $t("app.faq.questions.question11.title") }}</strong>
      </p>
      <ol>
        <p>
          {{ $t("app.faq.questions.question11.value") }}
        </p>
      </ol>
      <br />

      <p>
        <strong> {{ $t("app.faq.questions.question12.title") }}</strong>
      </p>
      <ol>
        <p>{{ $t("app.faq.questions.question12.value") }} <br /></p>
      </ol>
      <br />
      <p>
        <strong> {{ $t("app.faq.questions.question13.title") }}</strong>
      </p>
      <ol>
        <p>
          {{ $t("app.faq.questions.question13.value") }}
        </p>
      </ol>

      <p>
        <br />
        <strong> {{ $t("app.faq.questions.question14.title") }}</strong>
      </p>
      <ol>
        <p>
          {{ $t("app.faq.questions.question14.value") }}
        </p>
      </ol>

      <strong><br /> </strong>
      <h2>
        <strong> {{ $t("app.faq.frequently.title.label") }}</strong
        ><strong>{{ $t("app.faq.frequently.title.value") }}</strong>
      </h2>

      <p>
        <strong>{{
          $t("app.faq.frequently.questions.question1.title")
        }}</strong>
      </p>
      <ol>
        <p>
          {{ $t("app.faq.frequently.questions.question1.value") }}
        </p>
      </ol>
      <p>
        <strong>{{
          $t("app.faq.frequently.questions.question2.title")
        }}</strong>
      </p>
      <ol>
        <p>
          {{ $t("app.faq.frequently.questions.question2.value") }}
        </p>
      </ol>
      <p>
        <strong>{{
          $t("app.faq.frequently.questions.question3.title")
        }}</strong>
      </p>
      <ol>
        <p>
          {{ $t("app.faq.frequently.questions.question3.value") }}
        </p>
      </ol>
      <p>
        <strong>{{
          $t("app.faq.frequently.questions.question4.title")
        }}</strong>
      </p>
      <ol>
        <p>
          {{ $t("app.faq.frequently.questions.question4.value") }}
        </p>
      </ol>
      <p>
        <strong>{{
          $t("app.faq.frequently.questions.question5.title")
        }}</strong>
      </p>
      <ol>
        <p>
          {{ $t("app.faq.frequently.questions.question5.value") }}
        </p>
      </ol>
      <p>{{ $t("app.faq.more") }}</p>

      <a
        href="https://www.wfh.org/en/our-work-research-data/world-bleeding-disorders-registry"
      >
        {{ $t("app.faq.link") }}
      </a>
    </div>
  </v-card>
</template>
<script>
export default {};
</script>