import Vue from 'vue'
import VueRouter from "vue-router";
// Components
import FAQ from '../components/FAQ.vue'
import TermsOfUse from '../components/TermsOfUse.vue'
import PrivacyPolicy from '../components/PrivacyPolicy.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/faq',
        redirect: '/en/faq'
    },
    {
        path: '/terms-of-use',
        redirect: '/en/terms-of-use'
    },
    {
        path: '/privacy-policy',
        redirect: '/en/privacy-policy'
    },
    {
        path: '/:locale/faq',
        name: "FAQ",
        components: {
            default: FAQ
        }
    },
    {
        path: '/:locale/terms-of-use',
        name: 'TermsOfUse',
        components: {
            default: TermsOfUse
        }
    },
    {
        path: '/:locale/privacy-policy',
        name: 'PrivacyPolicy',
        components: {
            default: PrivacyPolicy
        }
    },
    { path: '*', redirect: '/faq' }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router