<template>
  <v-card elevation="0" tile>
    <div v-if="this.$i18n.locale == 'pt' || this.$i18n.locale == 'ar'">
      <v-card-text class="pb-0" v-html="$t('app.termhtml')"></v-card-text>
    </div>
    <div v-else>
      <h1>
        {{ $t("app.term.title") }}
      </h1>

      <h2>{{ $t("app.term.subtitle") }}</h2>
      <br />
      <p>
        <strong>{{ $t("app.term.subtitle2.label") }}</strong>
        {{ $t("app.term.subtitle2.value") }}
      </p>
      <p>{{ $t("app.term.term_list.title") }}</p>
      <p>
        <strong>{{ $t("app.term.term_list.value.term1.label") }}</strong>
      </p>
      <ol start="1" type="1">
        <li>
          {{ $t("app.term.term_list.value.term1.value.item1") }}
        </li>
        <li>
          {{ $t("app.term.term_list.value.term1.value.item2") }}
        </li>
        <li>
          {{ $t("app.term.term_list.value.term1.value.item3") }}
        </li>
      </ol>
      <p>
        <strong>{{ $t("app.term.term_list.value.term2.label") }}</strong>
      </p>
      <ol start="1" type="1">
        <li>
          {{ $t("app.term.term_list.value.term1.value.item1") }}
        </li>
      </ol>
      <p>
        <strong>{{ $t("app.term.term_list.value.term3.label") }}</strong>
      </p>
      <ol start="1" type="1">
        <li>
          {{ $t("app.term.term_list.value.term3.value.item1") }}
        </li>
      </ol>
      <p>
        <strong>{{ $t("app.term.term_list.value.term4.label") }}</strong>
      </p>
      <ol start="1" type="1">
        <li>You must not:</li>
        <ol start="1" type="1">
          <li>
            {{ $t("app.term.term_list.value.term4.value.item1.subitem1") }}
          </li>
          <li>
            {{ $t("app.term.term_list.value.term4.value.item1.subitem2") }}
          </li>
          <li>
            {{ $t("app.term.term_list.value.term4.value.item1.subitem3") }}
          </li>
          <li>
            {{ $t("app.term.term_list.value.term4.value.item1.subitem4") }}
          </li>
          <li>
            {{ $t("app.term.term_list.value.term4.value.item1.subitem5") }}
          </li>
          <li>
            {{ $t("app.term.term_list.value.term4.value.item1.subitem6") }}
          </li>
          <li>
            {{ $t("app.term.term_list.value.term4.value.item1.subitem7") }}
          </li>
          <li>
            {{ $t("app.term.term_list.value.term4.value.item1.subitem8") }}
          </li>
          <li>
            {{ $t("app.term.term_list.value.term4.value.item1.subitem9") }}
          </li>
        </ol>
      </ol>
      <p>
        <strong> {{ $t("app.term.term_list.value.term5.label") }}</strong>
      </p>
      <ol start="1" type="1">
        <li>
          {{ $t("app.term.term_list.value.term5.value.item1") }}
        </li>
        <li>
          {{ $t("app.term.term_list.value.term5.value.item2") }}
        </li>
      </ol>
      <p>
        <strong>{{ $t("app.term.term_list.value.term6.label") }}</strong>
      </p>
      <ol start="1" type="1">
        <li>
          {{ $t("app.term.term_list.value.term5.value.item1") }}
        </li>
        <li>
          {{ $t("app.term.term_list.value.term5.value.item2") }}
        </li>
        <li>
          {{ $t("app.term.term_list.value.term5.value.item3") }}
        </li>
      </ol>
      <p>
        <strong>{{ $t("app.term.term_list.value.term7.label") }}</strong>
      </p>
      <ol start="1" type="1">
        <li>
          {{ $t("app.term.term_list.value.term7.value.item1") }}
        </li>
      </ol>
      <p>
        <strong>{{ $t("app.term.term_list.value.term8.label") }}</strong>
      </p>
      <ol start="1" type="1">
        <li>
          {{ $t("app.term.term_list.value.term8.value.item1") }}
        </li>
        <li>
          {{ $t("app.term.term_list.value.term8.value.item2") }}
        </li>
      </ol>
      <p>
        <strong>{{ $t("app.term.term_list.value.term9.label") }}</strong>
      </p>
      <ol start="1" type="1">
        <li>
          {{ $t("app.term.term_list.value.term9.value.item1") }}
        </li>
      </ol>
      <p>
        <strong>{{ $t("app.term.term_list.value.term10.label") }}</strong>
      </p>
      <ol start="1" type="1">
        <li>
          {{ $t("app.term.term_list.value.term10.value.item1") }}
        </li>
      </ol>
      <p>
        <strong>{{ $t("app.term.term_list.value.term11.label") }}</strong>
      </p>
      <ol start="1" type="1">
        <li>
          {{ $t("app.term.term_list.value.term11.value.item1") }}
          <a href="mailto:mywbdr@wfh.org">
            {{ $t("app.term.term_list.value.term11.value.link") }}</a
          >.
        </li>
      </ol>
      <p>
        <strong>{{ $t("app.term.term_list.value.term12.label") }}</strong>
      </p>
      <ol start="1" type="1">
        <li>
          {{ $t("app.term.term_list.value.term12.value.item1") }}
        </li>
      </ol>
      <p>
        <strong>{{ $t("app.term.term_list.value.term13.label") }}</strong>
      </p>
      <ol start="1" type="1">
        <li>
          {{ $t("app.term.term_list.value.term13.value.item1") }}
        </li>
      </ol>
      <p>
        <strong>{{ $t("app.term.term_list.value.term14.label") }}</strong>
      </p>
      <ol start="1" type="1">
        <li>
          {{ $t("app.term.term_list.value.term14.value.item1") }}
        </li>
      </ol>
      <p>
        <strong>{{ $t("app.term.term_list.value.term15.label") }}</strong>
      </p>
      <ol start="1" type="1">
        <li>
          {{ $t("app.term.term_list.value.term15.value.item1") }}
        </li>
      </ol>
      <p>
        <strong>{{ $t("app.term.term_list.value.term16.label") }}</strong>
      </p>
      <ol start="1" type="1">
        <li>
          {{ $t("app.term.term_list.value.term16.value.item1") }}
        </li>
      </ol>
      <p>
        <strong>{{ $t("app.term.term_list.value.term17.label") }}</strong>
      </p>
      <ol start="1" type="1">
        <li>
          {{ $t("app.term.term_list.value.term17.value.item1") }}
        </li>
      </ol>
      <p>
        <strong>{{ $t("app.term.term_list.value.term18.label") }}</strong>
      </p>
      <ol start="1" type="1">
        <li>
          {{ $t("app.term.term_list.value.term18.value.item1") }}
        </li>
      </ol>
      <p>
        <strong>{{ $t("app.term.term_list.value.term19.label") }}</strong>
      </p>
      <ol start="1" type="1">
        <li>
          {{ $t("app.term.term_list.value.term19.value.item1") }}
        </li>
      </ol>
      <p>
        <strong>{{ $t("app.term.term_list.value.term20.label") }}</strong>
      </p>
      <ol start="1" type="1">
        <li>
          {{ $t("app.term.term_list.value.term20.value.item1") }}
        </li>
      </ol>
      <p>
        <strong>{{ $t("app.term.term_list.value.term21.label") }}</strong>
      </p>
      <ol start="1" type="1">
        <li>
          {{ $t("app.term.term_list.value.term21.value.item1") }}
        </li>
      </ol>
    </div>
  </v-card>
</template>
<script>
export default {};
</script>